import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';

const Universities = () => {
  const [partners, setPartners] = useState([]);

  useEffect(() => {
    fetch('https://lunarsenterprises.com:5003/studyabroad/list/partner')
      .then((response) => response.json())
      .then((data) => {
        if (data.result) {
          setPartners(data.data);
        }
      })
      .catch((error) => console.error('Error fetching partner data:', error));
  }, []);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    pauseOnHover: false,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 3,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      }
    ]
  };

  return (
    <div className='slide-part ' style={{ width: '100%', margin: '0 auto' }}>
      <Slider {...settings}>
        {partners.map((partner) => (
          <div key={partner.p_id} className='partner-bg-image'>
            <img src={`https://lunarsenterprises.com:5003/${partner.p_image}`} alt={`Partner ${partner.p_id}`} className='partner-image' />
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default Universities;
