import React, { useState, useEffect } from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

const Final = () => {
  const [deviceType, setDeviceType] = useState('desktop'); // Default device type
  const [destinations, setDestinations] = useState([]); // State to store fetched data

  // Detect device type on component mount
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 1024) {
        setDeviceType('desktop');
      } else if (window.innerWidth >= 464) {
        setDeviceType('tablet');
      } else {
        setDeviceType('mobile');
      }
    };

    // Set initial device type
    handleResize();

    // Event listener for window resize
    window.addEventListener('resize', handleResize);

    // Clean up event listener
    return () => window.removeEventListener('resize', handleResize);
  }, []); // Empty dependency array ensures this effect runs only on mount and unmount

  // Fetch data from API on component mount
  useEffect(() => {
    fetch('https://lunarsenterprises.com:5003/studyabroad/list/professional/destination')
      .then((response) => response.json())
      .then((data) => {
        if (data.result) {
          setDestinations(data.data);
        }
      })
      .catch((error) => console.error('Error fetching data:', error));
  }, []); // Empty dependency array ensures this effect runs only on mount

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
      slidesToSlide: 3 // Number of slides to scroll at once
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1
    }
  };

  return (
    <Carousel
      swipeable={true}
      draggable={true}
      showDots={false}
      responsive={responsive}
      ssr={true} // Server Side Rendering, true or false
      infinite={true}
      autoPlay={deviceType !== 'mobile'} // Auto play when not on mobile
      autoPlaySpeed={3000}
      keyBoardControl={true}
      customTransition="all .5"
      transitionDuration={500}
      containerClass="carousel-container"
      removeArrowOnDeviceType={['tablet', 'mobile']}
      deviceType={deviceType} // 'desktop', 'tablet', or 'mobile'
      dotListClass="custom-dot-list-style"
      itemClass="carousel-item-padding-40-px"
    >
      {destinations.map((destination) => (
        <div className='carouselitemjob' key={destination.p_id}>
          <div className='carousel-containerjob'>
           
            <img
            className="carousel-imgjob"
            alt={destination.p_place}
            src={`https://lunarsenterprises.com:5003/${destination.p_image}`}
          />
            <div className=" mt-1">
            <h5 className='desjobmain'>
            {destination.p_place}
            </h5>
            </div>
            <p className='desjob'>{destination.p_description}</p>
          </div>
        </div>
      ))}
    </Carousel>
  );
};

export default Final;
