import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js'; // Ensure you import the bundled JS file
import { Route, Routes } from "react-router-dom";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";



import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import './styles/globals.css'
// import './styles/homepage.css'
// import './styles/responsive.css'
// import './styles/timeline.css'
// import './styles/elementor.css'
// import './styles/multiRangeSlider.css'
// import './styles/formandpopup.css'







import Landingpage from './pages/index';
import Nofication from './pages/Nofication/Nofication';


function App() {
  return (
    <div>
      
      <Routes>
        <Route path="/" element={<Landingpage />} />
        <Route path="/Nofication" element={<Nofication />} />



      </Routes>
      
    </div>
  );
}

export default App;
