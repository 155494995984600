import React, { useState, useEffect } from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

const Testmoni= () => {
  const [deviceType, setDeviceType] = useState('desktop');
  const [testmoniallist, setTestmoniallist] = useState([]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 1024) {
        setDeviceType('desktop');
      } else if (window.innerWidth >= 464) {
        setDeviceType('tablet');
      } else {
        setDeviceType('mobile');
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    TestList();
  }, []);

  const TestList = async () => {
    try {
      const response = await fetch('https://lunarsenterprises.com:5003/studyabroad/list/testmonials');
      const data = await response.json();
      setTestmoniallist(data.data);
    } catch (error) {
      console.error(error);
    }
  };

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
      slidesToSlide: 2
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1
    }
  };

  return (
    <Carousel
      swipeable={true}
      draggable={true}
      showDots={false}
      responsive={responsive}
      ssr={true}
      infinite={true}
      autoPlay={deviceType !== 'mobile'}
      autoPlaySpeed={3000}
      keyBoardControl={true}
      customTransition="all .5"
      transitionDuration={500}
      containerClass="carousel-container"
      removeArrowOnDeviceType={['tablet', 'mobile', 'desktop']}
      deviceType={deviceType}
      dotListClass="custom-dot-list-style"
      itemClass="carousel-item-padding-40-px"
    >
      {testmoniallist.map((item) => (
        <div key={item.t_id} className='carouselitemTestimonial'>
          <div className='carousel-containerTestimonial'>
            <img
              className="carousel-imgTestimonial"
              alt={item.t_name}
              src={`https://lunarsenterprises.com:5003/${item.t_image}`}
              onError={(e) => { e.target.src = 'https://via.placeholder.com/150'; }} // Placeholder image for testing
            />
            <div className="carousel-titleTestimonial mt-1">{item.t_name}</div>
            <p className='carousel-titleTestimonialsub'>JOB VISA, {item.t_place.toUpperCase()}</p>
            <div className='heightty'>
              <p className='dessub'>{item.t_description}</p>
            </div>
          </div>
        </div>
      ))}
    </Carousel>
  );
};

export default Testmoni;
